
@import "tagify";
@import "dragsort";
@import "item_list";
@import "notice";
@import "font";
@import "mobile";
@import "flex-textarea";
@import 'swiper/swiper-bundle'; // swiperライブラリの読み込み
@import 'swiper';               // 自作のSCSSの読み込み
@import 'supp_appl'; // 購入申請(カート)用
@import 'supp_appl_book'; // 購入簿
@import 'supp_appr'; // 購入承認(カート)用
@import 'purc_appl'; // 決裁申請(カート)用
@import 'purc_appr'; // 決裁承認(カート)用
@import 'shipping';  // 在庫払出用
@import 'shipping_invoice';  // 在庫払出請求
@import 'acceptances'; // 納品書
@import 'order'; // 発注書
@import 'distribution'; // 振分
@import 'delivery';  // 納品
@import 'invoice';  // 請求書
@import 'transfer';  // 貸出依頼
@import 'inventory_detail'; // 商品棚卸
@import 'return_item'; // 返品・交換
@import 'stock';  // 在庫
@import 'stock_datatable';  // 在庫一覧データテーブル
@import 'stock_bulk_modification'; // 在庫一括登録
@import 'receipts'; // 受領
@import 'item'; // 商品マスタ
@import 'direction'; // 指示書
@import 'favorite_list'; // お気に入りリスト
@import 'barcode_search_bulk_supp_appl'; // バーコード検索一括購入カート追加
@import 'asman/autocomplete'; // 資産管理

// フラッシュメッセージが長いときに改行されるようにする
.alert {
  word-wrap: break-word;
}

// ボタンのアルファベットを大文字に変換しないようにする
.btn {
  text-transform: none;
  &.btn-outline {
    background-color: white;
  }
}

// ロゴののアルファベットを大文字に変換しないようにする
.sidebar .logo .simple-text {
  text-transform: none;
}

// クリックできる要素について、ホバー時に背景とポインタを変更する
.clickable:hover {
  background-color: rgba(153, 153, 153, 0.2);
  cursor: pointer;
}

// リンクのテキストのデザイン
a.link-text {
  color: #03c;
  text-decoration: underline;
}

// クリックできる要素について、ホバー時にポインタを変更する
.simple-clickable:hover {
  cursor: pointer;
}

// ホバー時に背景とポインタを変更する
.hover-grey:hover {
  background-color: rgba(153, 153, 153, 0.2);
}

// 商品検索バー
$sform-caret : 0.6em;
#sform {
  letter-spacing: -.4em;;
  div.dropdown {
    width: 25%;
    letter-spacing: normal;
  }

  div.filter-option {
    font-size: 16px;
    padding-left: 10px;
    padding-top: 9px;
    padding-bottom: 10px;
    .filter-option-inner {
      text-align: center;
    }
  }

  .dropdown-toggle::after {
    margin-top: 10px;
    vertical-align: 0;
    border-top: $sform-caret solid;
    border-right: $sform-caret solid transparent;
    border-left:  $sform-caret solid transparent;
  }

  div.bootstrap-select > .btn {
    height: 40px;
    margin: 0;
    border-radius: 5px 0 0 5px;
  }
}


  // 検索ボックス（visible)
  #v-sbox {
    -webkit-appearance: none;
    display: inline-block;
    margin-right: 0;
    padding: 10px;
    width: calc(95% - 50px);
    height: 40px;
    font-size: 100%;
    box-sizing: border-box;
    border-radius: 5px 0 0 5px;
    border: 1px solid #ccc;
    border-right: none;
    vertical-align: middle;
  }

    // 検索ボタン（visible)
  #v-sbtn {
    -webkit-appearance: none;
    display: inline-block;
    padding: 0 10px;
    color: #fff;
    // width: 10%;
    height: 40px;
    font-size: 30px;
    vertical-align: middle;
    border: none;
    border-radius: 0 5px 5px 0;
    background: #e91e63;
  }

  #v-sbtn:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  // 検索ボックス

.dummy-search {
  display: inline;
  font-size: 16px;
  width: 150px;
  padding-top: 11px;
  padding-bottom: 11px;
  height: 40px;
  margin: 0;
  border-radius: 5px 0 0 5px;
}

#sbox,
.item-list-search-area .page-sbox {
  -webkit-appearance: none;
  display: inline-block;
  margin-right: 0;
  padding: 10px;
  // width: 60%;
  width: 400px;
  height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  // border-radius: 5px 0 0 5px;
  border: 1px solid #ccc;
  border-right: none;
  border-left: none;
  vertical-align: middle;
  // safari向け。未設定を明示
  border-radius: 0;

  // 分析画面の商品検索用
  &.page-sbox {
    // なるべく高さを小さめに
    height: 32px;
    width: calc(100% - 40px);
    border-left: solid 1px #ccc;

    // 高さを小さくした関係上入力文字のフォントサイズも小さくする
    font-size: 14px;

    // 左のボタンがないので左側のborderに丸みをもたせる
    border-radius: 5px 0 0 5px;
  }
}

// 検索ボタン
#sbtn,
.item-list-search-area .page-sbtn {
  -webkit-appearance: none;
  display: inline-block;
  padding: 0 10px;
  color: #fff;
  // width: 100px;
  height: 40px;
  font-size: 30px;
  vertical-align: middle;
  border: none;
  border-radius: 0 5px 5px 0;
  background: theme-color("navy"); //#e91e63;

  // 分析画面の商品検索用
  &.page-sbtn {
    // なるべく高さを小さめに
    height: 32px;
    width: 40px;

    // 高さを小さくした関係上虫眼鏡アイコンのフォントサイズも小さくする
    font-size: 20px;
  }
}

#sbtn:hover,
.item-list-search-area .page-sbtn:hover {
  cursor: pointer;
  background: lighten(theme-color("navy"), 10%);
  //opacity: 0.9;
}

// カテゴリー一覧
.category-container {
  position: relative;
  overflow: hidden;
  height: 150px;
  transition:all 0.5s ease-out;
  text-align: center;
  background-color: #fff;
}

.category-container:hover {
  background-color: rgba(153, 153, 153, 0.2);
  cursor: pointer;
}

.category-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  transition: all .3s ease-out;
  color: #333;
}

.category-thumbnail {
  width: 80px;
  height: 80px;
  margin: auto;
  mix-blend-mode: darken;
}

.category-image {
  max-width: 100%;
  max-height: 100%;
}

.category-title {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  overflow: hidden;
  margin-top: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}

.category-item-list {
  position: absolute;
  top: 146px;
  width: 100%;
}

.category-item {
  font-size: 12px;
  line-height: 1.3;
}

.category-item:not(:first-child) {
  margin-top: 12px;
}

.category-item-link {
  display: block;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-item-link:hover {
  text-decoration: underline;
}

// 子カテゴリ一覧

div.category-list > .row > [class^=col-] {
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  box-sizing: border-box;
}

div.category-list > .row > [class^=col-]:nth-child(6n) {
  border-right: none;
}

.child-category-container {
  position: relative;
  overflow: hidden;
  height: 200px;
  transition:all 0.5s ease-out;
  text-align: center;
  background-color: #fff;
}

.child-category-container:hover {
  background-color: rgba(153, 153, 153, 0.2);
  cursor: pointer;
}

.child-category-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  transition: all .3s ease-out;
  color: #333;
}

.child-category-thumbnail {
  width: 100px;
  height: 100px;
  margin: auto;
  mix-blend-mode: darken;
}

.child-category-image {
  max-width: 100%;
  max-height: 100%;
}

.child-category-title {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  overflow: hidden;
  margin-top: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}

// item一覧
/*
div.item-list > .row > [class^=col-] {
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  box-sizing: border-box;
}

div.item-list > .row > [class^=col-]:nth-child(4n) {
  border-right: none;
}

.item-container {
  position: relative;
  overflow: hidden;
  height: 318px;
  transition:all 0.5s ease-out;
  text-align: center;
  background-color: #fff;
}

.item-container:hover {
  background-color: rgba(153, 153, 153, 0.2);
  cursor: pointer;
}

.item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  transition: all 0.3s ease-out;
  color: #333;
}

.item-thumbnail {
  width: 100%;
  height: 70%;
  margin: auto;
  mix-blend-mode: darken;
}

.item-image {
  max-width: 100%;
  max-height: 100%;
}

.item-title {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  margin-top: 16px;
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 14px;
    max-height: 36px;
    line-height: 1.5;
  }
}

.item-manufacturer {
  text-align: left;
  font-size: 13px;
  color: #565959;
}
*/
// 商品詳細
#item-detail-modal #item-modal-title {
  word-break: break-all;
}
.item-detail-container {
  height: 600px;
}

.item-detail-container:first-child {
  border-right: 1px solid #ebebeb;
}

.item-detail-thumbnail {
  width: 100%;
  height: 70%;
  margin: auto;
}

.item-detail-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item-detail-title {
  font-size: 24px!important;
  line-height: 32px!important;
}

.item-detail-manufacturer {
  text-align: left;
  font-size: 13px;
  color: #565959;
}

// 商品選択の商品詳細の文字サイズ等
div.item-detail-pane {
  font-size: 0.875rem;
  word-break: break-all;
  div.row {
    div[class^=col-] {
      border-bottom: 1px solid #dee2e6 !important;
    }
    div.item-label {
      color: #757575 !important;
      background-color: #f5f5f5;
    }
  }
}

.detail-section td {
  padding: 4px;
}

.a-span3 {
  font-size: 14px!important;
  font-weight: 700!important;
  width: 26.18%;
}

.a-span9 {
  font-size: 14px!important;
  width: 78.68%;
}

.button-section {
  height: 240px;
  margin-top: 40px;
}

// .col- .bmd-form-group .bootstrap-select となった時にmax-width: 220pxとなってるが解除する
[class*="col-"] .bmd-form-group .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  max-width: initial;
}

// bmd-* ではなく、bs-form-controlのときmax-width解除
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  max-width: initial;
}

// ヘッダのユーザ名表示
#navbarDropdownProfile span {
  font-size: 1.125rem;
  font-weight: bold;
}

// 購入申請詳細画面買い物カゴ

.sc-list-item {
}

.sc-list-item-border {
  border-bottom: 1px solid #DDD;
}

.sc-list-item-border:first-child {
  border-top: 1px solid #DDD;
}

.sc-list-item-content {
  margin-top: 15px!important;
  margin-bottom: 15px!important;
  // border-bottom: 1px solid #DDD;
}

.fixed-left-grid {
  // background-color: #333;
  height: 100%;
}

.fixed-left-grid-inner {
  // background-color: red;
  height: 100%;
}

.sc-list-item-checkbox {
  margin-top: 80px;
}

.sc-list-item-image {
  padding-left: 15px;
  height: 100%;
}

.sc-product-title {
  font-size: 18px!important;
  line-height: 24px!important;
  color: #0F1111;
}

// widthを調整するためのクラス
@for $i from 1 through 100 {
  .w-#{$i}rem {
    width: #{$i}rem !important;
  }

  .min-w-#{$i}rem {
    min-width: #{$i}rem !important;
  }

  .max-w-#{$i}rem {
    max-width: #{$i}rem !important;
  }

  .fix-w-#{$i}rem {
    width: #{$i}rem !important;
    min-width: #{$i}rem !important;
    max-width: #{$i}rem !important;
  }
}

.w-auto {
  width: auto !important;
}

// td 直下のpタグはマージンつけないようにする
td > p{
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.sidebar {
  box-shadow: none;
  .logo {
    padding: 7px 0px;
  }
}

.sidebar .nav {
  margin-top: 10px;
  li a {
    padding: 0 0 0 10px;
    font-size: 14px;
  }
  .nav {
    li a { padding-left: 25px; }
    .nav {
      li a { padding-left: 40px; }
    }
  }
  li .dropdown-menu a {
    font-size: 14px;
  }
}

// 申請承認の商品一覧のスタイル
table.sc-table {
  font-size: 0.875rem;

  > thead > tr > th{
    font-size: 12px;
    padding: 0.25rem 0.5rem;
  }

  > tbody > tr > td {
    padding: 0.25rem 0.5rem;
  }

  > tfoot > tr > th {
    font-size: 12px;
    padding: 0.25rem 0.5rem;
  }

  > tfoot > tr > td {
    padding: 0.25rem 0.5rem;
  }
  .sc-img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;
    img {
      width: 100%;
    }
  }
  .mobile-contents {
    display: flex;
    flex-wrap: wrap;
    > div:nth-child(2n+1) {
      width: 41%;
    }
    > div:nth-child(2n) {
      width: 59%;
    }
  }
  .mobile-contents-remand-form, .mobile-contents-approve-form {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
  }
  .mobile-contents-supply-application-table {
    display: flex;
    flex-wrap: wrap;
    > div:nth-child(2n+1) {
      width: 29%;
    }
    > div:nth-child(2n) {
      width: 71%;
    }
  }
}

/* datatablesのスタイル */
table.table.datatables, table.datatable-design {
  thead tr {
    th {
      font-size: 12px;
      text-align: center; // 基本的にヘッダ項目は中央寄せ(幅が狭く項目と矢印が重なる場合は適宜よせにする)
    }
    th.pl-3 {
      padding-left: 1rem !important;
    }
    th.pl-4 {
      padding-left: 1.5rem !important;
    }
  }
  tbody tr {
    td {
      word-break: break-all; // 連続する英字が折り返されないため強制折り返しを入れる; ellipsisで不要か？
      font-size: 12px;
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important;
    }
    td.pl-3 {
      padding-left: 1rem !important;
    }
    td.pl-4 {
      padding-left: 1.5rem !important;
    }
  }
  td.td-right {
    text-align: right;
    padding-right: 10px !important;
  }
  td.td-pr-3 {
    padding-right: 1rem !important;
  }
  td.td-pr-4 {
    padding-right: 1.5rem !important;
  }
  td.td-center {
    text-align: center;
  }
}

// cardのボタン群を上に寄せる
.card .card-btn-header {
  margin-top: -2.5rem;
}

// cardのmargin-topが広すぎるので縮小 -- 影響範囲がでかいので注意
.card {
  margin-top: 10px !important;
  // 併せてcard-iconを縮小し、cardの中に収める
  .card-icon {
    margin-top: 4px !important;
    margin-left: 8px !important;
    padding: 2px !important;
  }
}

// card-headerのz-indexが3なので、それより上に持ってきたい場合に使う
.zindex-over-ch {
  z-index: 4;
}

// form-control-plaintextまたはborder-bottom中のpタグはmargin-botoomを消す
.form-control-plaintext p,
.border-bottom p {
  margin-bottom: 0;
}

// contentのpaddingを減らす
// topのnavbarにpy-0を指定して20px縮めた分、margin-topも狭める
.main-panel > .content {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
}

// 在庫払出編集画面

.scroll {
  @media (max-width: 991px) {
    overflow: auto;
    // white-space: nowrap;
  }
}

.matrix-menu {
  position: fixed;
  // top: 0px;
  left: 0px;
  margin-left: 1rem;
  margin-right: 1rem;
  z-index: 5;
  width: calc(100% - 2rem);
}

.login-announcement-field {
  & > div {
    font-size: 14px;
    background-color: #FEF7F6;
    border: 2px solid #F35E4B;
    overflow: auto;
    max-height: 45vh;
  }

  .login-announcement-header-field {
    & > div {
      display: flex;
      align-items: center;
      color: #F35E4B;
      font-weight: bold;
    }
  }

  .login-announcement-message {
    color: black;
    background-color: #fff;
    border: 1px solid #2A5987;

    p:last-child {
      margin-bottom: 0px;
    }
  }
}

.dashboard-announces {
  background-color: #A4CCF0;
  color: white;
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
}

.dashboard-announces p {
  padding-top: 6px;
  margin:0;
  display:inline-block;
  white-space:wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;
  .announce-title {
    color: navy;
    font-weight: bold;
    cursor: hand;
    cursor:pointer;
  }
  .announce-readmore {
    font-size: 14px;
    color: navy;
    margin: 10px;
    text-decoration: underline;
    cursor: hand;
    cursor:pointer;
  }
}

@keyframes marquee {
	from   { transform: translate(0%);}
	99%,to { transform: translate(-100%);}
}

// テーブル
table#shipping-details-matrix {
  border-collapse: separate;
  border-spacing: 0px;
  width: auto;

  th, td {
    background-color: #ffffff;
    padding: 8px 8px;
  }

  thead {
    th {
      vertical-align: middle;
      text-align: center;
      background-color: #f2f2f3 !important;
      font-size: 0.8rem !important;
      font-weight: bold;
    }
    tr.departments {
      th {
        @media (min-width: 992px) {
          position: sticky;
        }
        top: 0;
      }

      th:nth-child(1) {
        left: 260px;
        z-index: 3;
      }

      th:nth-child(n+2) {
        padding: 8px 8px;
        width: 85px !important;
        min-width: 85px !important;
        max-width: 85px !important;
        z-index: 2;
      }

    }
    tr.items {
      th {
        @media (min-width: 992px) {
          position: sticky;
        }
        top: 41px;
        z-index: 3;
      }

      th:nth-child(1) {
        height: 16px !important;
        min-height: 16px !important;
        max-height: 16px !important;
        width: 15px !important;
        min-width: 15px !important;
        max-width: 15px !important;
        left: 260px;
      }
      th:nth-child(2) {
        height: 16px !important;
        min-height: 16px !important;
        max-height: 16px !important;
        width: 280px !important;
        min-width: 280px !important;
        max-width: 280px !important;
        left: 295px;
      }
      th:nth-child(3) {
        height: 16px !important;
        min-height: 16px !important;
        max-height: 16px !important;
        width: 100px !important;
        min-width: 100px !important;
        max-width: 100px !important;
        left: 575px;
      }
      th:nth-child(n+4) {
        height: 16px !important;
        min-height: 16px !important;
        max-height: 16px !important;
        width: 70px !important;
        min-width: 70px !important;
        max-width: 70px !important;
      }
      // th:nth-child(3) {
      //   left: 566px;
      // }
      th:nth-child(4) {
        left: 675px;
      }
      th:nth-child(5) {
        left: 745px;
      }
      th:nth-child(6) {
        left: 815px;
      }
    }
  }

  tbody {
    th, td {
      padding: 0 8px 0 8px;
      font-size: 0.9rem !important;
      font-weight: bold;
    }
    th {
      @media (min-width: 992px) {
        position: sticky;
      }
      z-index: 2;
    }
    th:nth-child(1) {
      left: 260px;
    }
    th:nth-child(2) {
      left: 295px;
    }
    th:nth-child(2):hover {
      // background-color: rgba(153, 153, 153, 0.2);
      cursor: pointer;
    }
    th:nth-child(3) {
      left: 575px;
    }
    th:nth-child(4) {
      left: 675px;
    }
    th:nth-child(5) {
      left: 745px;
    }
    th:nth-child(6) {
      left: 815px;
    }
    th:nth-child(n+3), td {
      text-align: right;
    }
  }
}

// モーダル
// sumテーブル
table#shipping-details-sum-matrix-modal {
  border-collapse: separate;
  border-spacing: 0px;
  // width: auto;

  th, td {
    background-color: #ffffff;
    padding: 0 8px 0 8px;
    font-size: 0.875rem !important;
  }

  thead {
    th {
      vertical-align: middle;
      text-align: center;
      background-color: #ddecf6 !important;
      font-weight: bold;
    }
  }
  tbody {
    td {
      text-align: end;
      font-weight: 400;
    }
  }
}
// 明細テーブル
table#shipping-details-matrix-modal {
  border-collapse: separate;
  border-spacing: 0px;
  // width: auto;

  th, td {
    background-color: #ffffff;
    padding: 0 8px 0 8px;
    font-size: 0.875rem !important;
  }

  thead {
    th {
      vertical-align: middle;
      text-align: center;
      background-color: #ddecf6 !important;
      font-weight: bold;
    }
    td {
      text-align: end;
    }
  }
  tbody {
    td {
      font-weight: 400;
    }
    td:nth-of-type(n+4):nth-of-type(-n+6) {
      text-align:end;
    }
  }
}

// 棚卸し
table.inventory-table {
  td.form {
    border: solid 2px #ff0000;
    border-top: solid 2px #ff0000 !important;
  }
}

// フォームの必須マーク
.required-field {
  padding-right: 0 !important; // after擬似要素が収まりやすくなるようpaddingをなくす
}
.required-field:after {
  content:"必須";
  color: #ffffff;
  background-color: #ff0000;
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.7rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
  margin-left: 3px;
}

// 軽減税率マーク
.reduced-tax-rate-label:after {
  content:"軽";
  color: #ffffff;
  background-color: theme-color("navy");
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.7rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// 非課税マーク
.tax-free-label:after {
  content:"非";
  color: #ffffff;
  background-color: theme-color("green");
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.7rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// メディエマーク
.medie-label:after {
  content:"メディエ";
  color: #ffffff;
  background-color: theme-color("navy");
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.7rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
  margin-left: 3px;
}

// フォームで使用するラベルの固定幅
// 画面左端以外から始まる<label>タグに使用する
.form-label-width {
  width: 85px;
}

// selectpickerが一回り小さいフォントになる問題への対応
div.filter-option {
  font-size: 14px;
  padding-top: 4px;
}

// ajax-bootstrap-selectのstatusのデザイン
// "検索中"などが表示される時のデザインを統一させたい
div.abs-status-msg {
  margin-top: 0.3125rem;
  padding: 0.3125rem;
  border-top: 1px solid #e9ecef;
  text-align: center;
  color: #777;
}

div.bootstrap-select {
  // modalのz-indexが1050，navbar(通知ドロップダウン含む)のz-indexが1029なのでそれらより大きくする
  .dropdown-menu {
    z-index: 1050;
  }
  .dropdown-menu,.inner.show {
    // min-heightを0にしないと，ドロップダウンに空白が生まれてしまう
    // space-white: normalをcssで指定したためにmin-heightがバグっていしまっているので，これで無理矢理更新
    min-height: 0px !important;
  }
  // bootstrap-selectのoptionの長さが長い時に折り返す
  .dropdown-item {
    white-space: normal;
    word-break: break-all; // 連続する英字が折り返されないため強制折り返しを入れる
  }
  // marginとpaddingでかなりの高さがあるので、それを修正
  .dropdown-toggle {
    margin: 0;
    padding-top: 5px;
    padding-right: 10px;
    .filter-option {
      padding-top: 7px; // bs-col-form-labelのテキストとほぼ同じ位置にするため
    }
  }
}



// フォーム内項目の追加/削除ボタン
.add-and-remove-item-field-box {
  padding-top: 23px;
  .add-item-field, .remove-item-field {
    font-size: 21px;
    cursor: pointer;
  }
}

// tabデザイン用
$menu-tab-active-color: theme-color("navy"); // #4169e1;
$menu-tab-active-border-color: theme-color("navy"); // #4169e1;
.menu-tab {
  padding-left: 40px;
  background-color: white !important;
  .nav-tabs .nav-item .fix-nav-link.active {
    background-color: $menu-tab-active-color;
  }
  border-bottom: 3px solid $menu-tab-active-border-color;
}
.nav-tabs .nav-item .fix-nav-link {
  font-size: 14px !important;
  color: gray !important;
  padding: 5px 15px 2px 15px;
  border-radius: 3px 3px 0 0 !important;
  border-color: white white gray white !important;
  border-style: solid !important;
  border-width: 1px !important;
  background-color: #eeeeee;
  &:hover {
    color: $menu-tab-active-color !important;
    border-radius: 3px 3px 0 0 !important;
    border-color: white white gray white !important;
    border-style: solid !important;
    border-width: 1px !important;
  }
}
.nav-tabs .nav-item .fix-nav-link.active {
  color: white !important;
  border-color: $menu-tab-active-border-color !important;
  border-style: solid !important;
  border-width: 1px !important;
  &:hover, &:focus, &:active {
    color: white !important;
    border-color: gray !important;
  }
}

// formっぽいshowやconfirmのborder-color変更用
div.form-control-plaintext.border-bottom {
  border-bottom-color: #666666 !important;
}

// tdだけ右寄せにする設定
// TODO: td-rightという専用クラスがあるためそちらに寄せるように
.dataTable {
  > tbody > tr > td.number-column {
    // DataTablesのcssで!importantが指定されており, それを上書きするために!importantを指定
    padding-right: 10px !important;
    text-align: right;
  }
  .download-error-icon,.download-diff-icon {
    color: navy;
    //vertical-align: middle;
    cursor: pointer;
    //font-size: 18px;
  }
}

.white-box-screen {
  position: fixed;
  width: 20px;
  height: 35px;
  background: white;
  top: 0;
  z-index: 2;
  &.left {
    left: 260px;
    @media only screen and (max-width: 991px) {
      left: 0;
    }
  }
  &.right {
    right: 0;
  }
}

div.form-check-radio {
  label {
    // ラジオボタンのデフォルトの文字色が薄いグレーのため参照しづらい
    // 分かりやすいようにformのlabelと同じ色にする
    color: #666666;
  }
}

// data-confirm-modalのデザインを上書きするためのstyle
div.custom-modal {
  div.modal-footer {
    button {
      margin-left: 1rem;
      min-width: 6rem;
    }
  }
  &.cannot-commit {
    .commit {
      display: none;
    }
  }

  p {
    margin-bottom: 0.375rem;
  }
}

.bg-lightgray {
  background-color: #f4f4f4 !important;
}

.col-form-label {
  text-align: left !important;
}

.card {
  .card-body {
    .show-label {
      color: rgb(102, 102, 102);
      // bs-col-form-labelと同じにする。
      // 日本語は違いがないように感じるがアルファベットだとbs-fol-form-labelとの違いが目立つため
      font-weight: 400;
      &.route_name {
        @media only screen and (max-width: 991px) {
          width: 17%;
        }
      }
    }
  }
  .tab-content.tab-space {
    padding: 20px 10px 9px 10px;
  }

  .bs-form-control {
    font-size: 0.875rem !important;
  }
}

// 優先画像に外枠をつけるためのクラス
label.primary-image {
  border: solid 2px theme-color("bs-primary") !important;
  //box-shadow: 3px 3px 5px 5px rgba(0,67,100,0.4);
}


// 外側のlabelタグで囲んだときの大きさを調整するための設定
img.item-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

// 1:1で表示することを強調するためのクラス
// 画像の外側で囲む
// 縦と横を200pxにする
// 足りない部分は灰色で埋めて200pxにする
label.image-container {
  position: relative;
  width: 200px;
  height: 200px;
  background: #eee;
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

div.primary-badge {
  background: rgba(255, 255, 255, .8);
  padding-left: 3px;
  padding-right: 3px;
  position: absolute;
  top: 0px;
  right: 0px;
}

// new/edit画面での画像フィールド
// inputタグまたはimgタグを囲む
label.image-container-input {
  position: relative;
  width: 200px;
  height: 200px;
  background: #ccc;
  min-width: 200px;
  min-height: 200px;
  cursor: pointer;
  border: 1px solid theme-color("navy");
  // 画像を中央に表示
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  // 写真アイコンを中央に表示
  i {
    margin: auto;
  }
}

// div.attachment-field {
//   display: flex;
//   flex-flow: column;
// }

// div.attachment-new-field:hover {
//   border-color:#333 ;
// }

// label.attachment-container-input {
//   position: relative;
//   width: 210px;
//   height: 200px;
//   // height: 150px;
//   // background: #ccc;
//   min-width: 210px;
//   min-height: 200px;
//   // min-height: 150px;
//   cursor: pointer;
//   border: 2px dashed #dbdbdb;
//   border-radius: 0.25rem;
//   // アイコンを中央に表示
//   i {
//     // margin: auto;
//   }

// }

// div.attachment-container-footer {
//   padding: 0.75rem 1.25rem;
//   background-color: #fafafa;
//   border-right: 1px solid #dbdbdb;
//   border-bottom: 1px solid #dbdbdb;
//   border-left: 1px solid #dbdbdb;
//   border-bottom-left-radius: 0.25rem;
//   border-bottom-right-radius: 0.25rem;

//   width: 210px;
//   min-width: 210px;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
// }

// div.attachment-container-footer:hover {
//   overflow: visible;
//   white-space: normal;
//   height: auto;
// }

div#attachment-wrapper {
  border: 2px dashed #dbdbdb;
  border-radius: 0.25rem;
  min-height: 50px;;
}

.attachment-content {
  width: 220px;
  height: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  padding: 8px;
  background-color: #fafafa;
  font-weight: 500;

  a {
    color: black;
    text-decoration: none;
  }
  a:hover {
    color: black;
    text-decoration: underline;
  }
}

div#dropzone-area:hover {
  cursor: pointer;
}

i.delete-btn:hover {
  cursor: pointer;
}

// container-fluidの中が全体cardの場合に、cardの左右に空白ができないようにする
// dashboardで副作用が炸裂したので、no-expand-rowというclassがついていたら除外
div.content > div.container-fluid > div.row:not([class*="no-expand-row"])  {
  > div.col-12,div.col-sm-12,div.col-md-12,div.col-lg-12,div.col-xl-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    > .card {
      margin-top: 0px !important;
      //min-height: 70vh;
    }
  }
}

// 仮ダッシュボードの文字用
.text-shadow-gray {
  text-shadow: 5px 5px 5px #aaa;
}
@each $t in ("brown", "orange", "gold", "green", "lime", "cyan", "navy", "dull-gold") {
  .text-#{$t}-dark {
    color: darken(theme-color($t), 15%);
  }
}
@each $t in ("brown", "orange", "gold", "green", "lime", "cyan", "navy", "dull-gold", "danger") {
  .text-#{$t}-light {
    color: lighten(theme-color($t), 15%);
  }
}
.ds-label {
  font-size: 1.8em;
}

// 仮ダッシュボードの線グラフの線の色用
#ct-total-prices {
  .ct-series-a {
    .ct-point,.ct-line {
      stroke: theme-color("navy") !important;
    }
  }
  .ct-series-b {
    .ct-point,.ct-line {
      stroke: theme-color("cyan") !important;
    }
  }
}

#ct-items {
  .ct-series-a {
    .ct-slice-pie {
      stroke: lighten(theme-color("navy"), 5%) !important;
      fill: lighten(theme-color("navy"), 5%) !important;
    }
  }
  .ct-series-b {
    .ct-slice-pie {
      //stroke: lighten(theme-color("navy"), 5%) !important;
      //fill: lighten(theme-color("navy"), 5%) !important;
      stroke: #FFD43B !important;
      fill: #FFD43B !important;
    }
  }
  .ct-series-c {
    .ct-slice-pie {
      stroke: lighten(theme-color("lime"), 10%) !important;
      fill: lighten(theme-color("lime"), 10%) !important;
    }
  }
  .ct-label {
    color: white !important;
    fill: white !important;
  }
}

// modal関連
.modal .modal-dialog {
  // modal-dailog-centered指定しても上下中央にこないのは
  // material dashboardのデフォルトでmargin-top: 100pxが
  // 指定されているから
  margin-top: 0px;
}

.modal-loading {
  z-index: 100000; // とても大きい値
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: table;
  background: rgba(255,255,255,0.5);
  div {
    display: table-cell;
    vertical-align: middle;
  }
}
.modal-loading.hidden {
  visibility: hidden;
}

.fullscreen-loading {
  z-index: 100000; // とても大きい値
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(255,255,255,0.5);

  &.hidden {
    visibility: hidden;
  }
}

// dropdown-menuのactive colorの変更
.dropdown-menu {
  .dropdown-item:hover,
  .dropdown-item:focus,
  a:hover,
  a:focus,
  a:active,
  a.active {
    @include shadow-small-color($bs-primary);
    background-color: $bs-primary;
    color: #FFFFFF;
  }
}

// selectpickerのactive colorの変更
.bootstrap-select .dropdown-item.active {
  background: #eee !important;
  color: black !important;
}
.bootstrap-select.btn-group .dropdown-menu li a {
    &:focus,
    &:hover{
        background-color: theme-color('bs-primary') !important;
        @include shadow-big-color(theme-color('bs-primary'));
    }
}

.bootstrap-select {
  .dropdown-item:hover,
  .dropdown-item:focus{
    background-color: theme-color('bs-primary') !important;
    color: white !important;
  }
}

// boostrap-selectでdisabledの時のデザイン
.bootstrap-select.disabled {
  // バツボタンとドロップダウンの下ボタンを非表示にする
  .clear-abs-button, .dropdown-toggle::after {
    visibility: hidden;
  }
  // 灰色にしてdisabled感を出す
  .filter-option {
    background: #eee;
  }
}

// boostrap-selectでdisabledの時のデザイン
// カーソルを変える(javascriptでクラスの変更する必要あり)
span.bmd-form-group.disabled {
  cursor: not-allowed;
}

// 太いborder
.border-thick {
  border-width: 2px !important;
}

// ajax modal form の閉じるボタンが確認よりでかいのを直すため
button.px-23px,input.px-23px {
  padding-left:  23px !important;
  padding-right: 23px !important;
}

// checkboxの色を変える
.form-check .form-check-input {
  &:focus + .form-check-sign .check:after {
      opacity: 0.2;
  }

  &:checked {
    & ~ .form-check-sign .check {
      background: theme-color("navy");
    }

    & ~ .form-check-sign .check:before {
      color: #FFFFFF;
      box-shadow: 0 0 0 10px,
                  10px -10px 0 10px,
                  32px 0 0 20px,
                  0px 32px 0 20px,
                  -5px 5px 0 10px,
                  20px -12px 0 11px;
      animation: checkboxOn $mdb-checkbox-animation-check forwards;
    }

    & ~ .form-check-sign:before {
      animation: rippleOn $mdb-checkbox-animation-ripple;
    }

    & ~ .form-check-sign .check:after {
      animation: rippleOn $mdb-checkbox-animation-ripple forwards;
    }
  }

  &:not(:checked) {
    & + .form-check-sign:before {
      animation: rippleOff $mdb-checkbox-animation-ripple;
    }

    & + .form-check-sign .check:after {
      animation: rippleOff $mdb-checkbox-animation-ripple; // Ripple effect on uncheck

    }
  }
}

// 経路表示用label
div.step-label {
  width: 10rem;
  min-width: 10rem;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background: white;
  font-size: 1.2rem;
}

// 経路の数字だけ大きくする
span.serial-num-text {
  font-size: 1.2em;
  font-weight: 900;
}

// +-ボタンに色をつける
.add-parallel-field-btn,.add-item-field {
  color: blue !important;
}
.del-parallel-field-btn,.remove-item-field {
  color: theme-color("danger") !important;
}

// dataTablesのヘッダのソート矢印をRobotoに変える
table.dataTable thead .sorting:before,table.dataTable thead .sorting:after,table.dataTable thead .sorting_asc:before,table.dataTable thead .sorting_asc:after,table.dataTable thead .sorting_desc:before,table.dataTable thead .sorting_desc:after,table.dataTable thead .sorting_asc_disabled:before,table.dataTable thead .sorting_asc_disabled:after,table.dataTable thead .sorting_desc_disabled:before,table.dataTable thead .sorting_desc_disabled:after{
  color: blue;
  font-weight: 900;
  font-size: 14px;
  padding-top: 3px;
  font-family:'Roboto';
}

// dataTablesをtext-overflow: ellipsisするためのclass
td.ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

// 幅をrem単位で指定する
// w2rem ~ w30rem までのクラスが生成される
// datatblesのcolumnsでellipsisしたい列のclassNameとしてellipsisと一緒に指定する
@for $i from 2 through 30 {
  .w#{$i}rem {
    width: #{$i}rem !important;
    min-width: #{$i}rem !important;
    max-width: #{$i}rem !important;
  }
}

// 上記CSSがmobile対応したdatatablesでの表示に干渉していたため打ち消す
@for $i from 2 through 30 {
  .mobile-datatables {
    li.w#{$i}rem {
      width: auto !important;
      min-width: auto !important;
      max-width: none !important;
    }
  }
}

// 管理の商品マスタ一覧の商品名の幅を与えるclass: text-overflowするために必要
// 残りの行はw*remでmax-widthを指定しているので余りとなる商品名に対してのmax-widthとなる
.w-admin-item-name {
  // 100vwではなく80vwにしているのは100vwだとdatatablesに横スクロールバーが発生してしまうから。
  // 20vwはいろんな要素のmarginとかの分と思えばいいのか？
  // 260pxはsidebarの幅、残りのremはdatatablesで与えた列幅
  max-width: calc(80vw - 260px - 3rem - 8rem - 8rem - 8rem - 6rem - 6rem - 8rem);
}

.w-admin-items-sales-price-supplier {
  max-width: calc(80vw - 260px - 3rem - 10rem - 10rem - 6rem - 6rem - 3rem - 2rem - 4rem);
}

.w-admin-items-item-history-user {
  max-width: calc(80vw - 260px - 3rem - 10rem);
}

// メディエ取り込みのファイル名の幅を与えるclass
.w-admin-medie-file-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 8rem - 4rem - 4rem - 5rem - 5rem);
}

// VHJ取り込みのファイル名の幅を与えるclass
.w-admin-vhj-file-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 8rem - 5rem - 6.5rem);
}

// SPD取り込みのファイル名の幅を与えるclass
.w-admin-spd-file-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 8rem - 5rem - 5rem);
}

// 商品区分取り込みのファイル名の幅を与えるclass
.w-admin-spd-file-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 8rem - 12rem - 5rem);
}

.w-items-facility-item-operation-facility {
  max-width: calc(80vw - 260px - 3rem - 6rem - 6rem - 5rem - 6rem - 6rem)
}

// 施設別商品マスタの商品マスタ一覧の商品名の幅
.w-items-facility-show-item-name {
  max-width: calc(80vw - 260px - 3rem - 5rem - 5rem)
}

.w-items-facilities-category {
  max-width: calc(80vw - 260px - 3rem - 6rem - 6rem);
}

// 製造販売企業の名称の幅
.w-admin-vendor-name {
  max-width: calc(80vw - 260px - 3rem - 5rem - 7rem - 6rem - 6rem);
}

// 部署一覧の部署名の幅
.w-admin-department-name {
  max-width: calc(80vw - 260px - 3rem - 20rem - 12rem - 5rem);
}

// ユーザー一覧の所属の幅
.w-admin-user-title {
  max-width: calc(80vw - 260px - 3rem - 5.5rem - 8rem - 12rem - 6rem);
}

// 経路雛形名の幅
.w-admin-template-name {
  max-width: calc(80vw - 260px - 3rem - 12rem - 12rem -  6rem - 5rem - 5rem);
}

.w-admin-template-misc-application-name {
  max-width: calc(80vw - 260px - 3rem - 14rem - 12rem - 12rem - 5rem);
}

// 施設一覧の住所の幅
.w-admin-facility-address {
  max-width: calc(80vw - 260px - 3rem - 20rem - 6rem - 6rem);
}

// 役職一覧の役職名の幅
.w-admin-position-name {
  max-width: calc(80vw - 260px - 5rem);
}

// 診療科一覧の診療科名の幅
.w-admin-diag-dept-name {
  max-width: calc(80vw - 260px - 5rem);
}

.w-admin-doctor-diag-dept {
  max-width: calc(80vw - 260px - 3rem - 15rem - 15rem);
}

// カテゴリー一覧の概要の幅
.w-admin-category-name {
  max-width: calc(80vw - 260px - 3rem - 20rem);
}

// 商品区分一覧の名称の幅
.w-admin-item-type-name {
  max-width: calc(80vw - 260px - 3rem - 12rem);
}

// 特徴・用途一覧の名称の幅
.w-admin-tag-name {
  max-width: calc(80vw - 260px - 3rem);
}

// 入札ファイル作成一覧のファイル名の幅
.w-admin-bid-export-file-name {
  max-width: calc(80vw - 260px - 3rem - 16rem - 8rem - 8rem);
}

// 入札ファイル詳細の商品名の幅
.w-admin-bid-export-item-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 6rem - 6rem - 6rem - 4rem - 6rem);
}

// 入札ファイル作成の商品名の幅
.w-admin-bid-export-new-item-name {
  max-width: calc(80vw - 260px - 2rem - 5rem - 5rem - 6rem - 5rem - 4rem - 5rem - 6rem);
}


// 入札ファイル取込一覧のファイル名の幅
.w-admin-bid-import-file-name {
  max-width: calc(80vw - 260px - 3rem - 8rem - 8rem - 5rem - 6.5rem);
}

// 仕入先一覧の仕入先名の幅
.w-admin-supplier-name {
  max-width: calc(80vw - 260px - 3rem - 16rem - 6rem - 6rem);
}

// 仕入先詳細の担当者タブのユーザー名の幅
.w-admin-supplier-supplier-user-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 6rem - 14rem - 6rem - 5.5rem);
}

// 仕入先ユーザー一覧の仕入先名の幅
.w-admin-supplier-user-supplier-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 6rem - 8rem - 14rem - 6rem - 5.5rem);
}

// お伺い書テンプレート一覧のテンプレート名の幅
.w-admin-misc-template-title {
  max-width: calc(80vw - 260px - 3rem - 20rem - 6rem);
}

// 購入申請一覧件名(全て)
.w-browse-supply-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 11rem - 5rem - 5rem - 5rem - 6rem);
}

// 全購入申請一覧の件名
.w-supp-appl-supply-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 17rem - 5rem - 5rem - 6rem);
}

// 編集中購入申請一覧の件名
.w-supp-appl-editings-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 10rem - 8rem);
}

// 差戻済み購入申請一覧の件名
.w-supp-appl-remanded-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 10rem - 8rem);
}

// 回覧中購入申請一覧の件名
.w-supp-appl-circulate-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 10rem - 10rem - 8rem);
}

// 承認済み購入申請一覧の件名
.w-supp-appl-approved-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 10rem - 10rem - 8rem);
}

// 取下げ済み購入申請一覧の件名
.w-supp-appl-withdrawn-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 10rem - 8rem);
}

// 却下済み購入申請一覧の件名
.w-supp-appl-rejected-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 10rem - 8rem);
}

// 承認待ち購入申請一覧の件名
// 表示するカラムが同じなので，承認待ちと回覧前を兼用している
.w-supp-appr-ongoings-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 13rem - 5rem - 6rem - 6rem);
}

// 対応済み購入申請一覧の件名
.w-supp-appr-processeds-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 18rem - 8rem - 5rem - 6rem);
}

// 決裁申請->新規作成の件名
.w-purc-appl-aggregations-supp-appl-list-title {
  max-width: calc(80vw - 260px - 2rem - 5rem - 17rem - 5rem - 6rem);
}

// 決裁申請一覧
.w-purc-appl-purchase-applications-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 11rem - 4rem - 5rem - 9rem - 5rem - 6rem);
}

// 承認待ち決裁申請一覧
// 表示するカラムが同じなので，承認待ちと回覧前を兼用している
.w-purc-appr-ongoings-title {
  max-width: calc(80vw - 260px - 3rem - 5rem - 12rem - 4rem - 6rem - 10rem - 6rem);
}

// 対応済み決裁申請一覧
.w-purc-appr-processeds-title {
  max-width: calc(80vw - 260px - 3rem - 4rem - 12rem - 6rem - 10rem - 5rem - 6rem);
}

// 在庫払出
// 院内在庫払出対象選択の件名
.w-shippings-shipping-histories-select-supp-appl-title {
  max-width: calc(80vw - 260px - 2rem - 5rem - 18rem - 8rem - 5rem - 6rem);
}
// 院内在庫払出履歴(グループ管理者)の払出先施設
.w-shippings-shipping-g-histories-facility {
  max-width: calc(80vw - 260px - 3rem - 15rem - 10rem);
}
// 院内在庫払出履歴(施設担当者)の実施者
.w-shippings-shipping-histories-user-name {
  max-width: calc(80vw - 260px - 10rem);
}

// グループ在庫払出（施設担当）払出依頼の件名
.w-shippings-shipping-histories-request-select-supp-appl-title {
  max-width: calc(80vw - 260px - 2rem - 5rem - 18rem - 5rem - 6rem);
}
// グループ在庫払出（施設担当）依頼済(グループ管理者)の払出先施設
.w-shippings-shipping-g-histories-request-index-facility {
  max-width: calc(80vw - 260px - 3rem - 5rem - 5rem - 5rem - 6rem - 6rem);
}
// グループ在庫払出（施設担当）依頼済(施設担当者)の依頼者と実施者
.w-shippings-shipping-histories-request-index-user {
  max-width: calc((80vw - 260px - 3rem - 6rem - 8rem - 8rem) / 2); // 2つのカラムの幅を決めるので2で割る
}

// グループ在庫払出（施設担当）依頼中
.w-shippings-shipping-requests-user-name {
  max-width: calc(80vw - 260px - 3rem - 10rem - 10rem - 15rem - 10rem);
}
// グループ在庫払出依頼一覧（施設担当）
.w-shippings-shipping-group-histories-user-name {
  max-width: calc(80vw - 260px - 3rem - 15rem - 7rem - 10rem - 10rem);
}
// グループ在庫払出（施設担当）確定済み
.w-shippings-shipping-group-histories-group-index-user-name {
  max-width: calc(80vw - 260px - 3rem - 7rem - 7rem - 5rem - 7rem - 7rem);
}
// グループ在庫払出（施設担当）却下済み
.w-shippings-shipping-group-histories-rejected-user-name {
  max-width: calc(80vw - 260px - 3rem - 20rem);
}

// グループ在庫払出（管理者）承認待ちの払出先施設
.w-shippings-shipping-group-histories-request-index-facility {
  max-width: calc(80vw - 260px - 3rem - 20rem - 10rem);
// グループ在庫払出（管理者）対応済みの払出先施設
.w-shippings-shipping-group-histories-facility {
  max-width: calc(80vw - 260px - 3rem - 5rem - 5rem - 5rem - 6rem - 6rem);
}
}

// グループ在庫払出（管理者）依頼中
.w-shippings-shipping-requests-group-user-name {
  max-width: calc(80vw - 260px - 3rem - 20rem - 20rem);
}
// グループ在庫払出（管理者）対応済み
.w-shippings-shipping-group-histories-facility-name {
  max-width: calc(80vw - 260px - 3rem - 12rem - 12rem - 12rem - 12rem);
}
// グループ在庫払出（管理者）却下済み
.w-shippings-shipping-group-histories-rejected-group-user-name {
  max-width: calc(80vw - 260px - 3rem - 20rem - 20rem);
}

// 発注
// 発注対象選択の件名
.w-orders-approved-purchase-application-title {
  max-width: calc(80vw - 260px - 2rem - 5rem - 5rem - 14rem - 6rem);
}
// 発注書一覧の請求書宛名部署
.w-orders-decided-orders-label-dept {
  max-width: calc(80vw - 260px - 3rem - 9rem - 4rem - 15rem - 6rem - 5rem - 6rem);
}
// 発注書履歴一覧
.w-orders-order-history-facility-name {
  max-width: calc(80vw - 260px - 3rem - 16rem - 10rem);
}

// 納品
// 発注書一覧の請求書宛名部署
.w-acceptances-deliveries-label-dept {
  max-width: calc(80vw - 260px - 3rem - 9rem - 15rem - 6rem - 6rem);
}

// 請求書
// 請求書一覧仕入れ先
.w-med-invoices-linked-invoices-supplier {
  max-width: calc(80vw - 260px - 3rem - 9rem - 15rem - 7rem - 6rem);
}

// 在庫
// 譲渡依頼履歴
// 譲渡元と譲渡先があるので2で割る
.w-stocks-request-transfers-fd {
  max-width: calc((80vw - 260px - 3rem - 5rem - 6rem - 5rem - 5rem - 6rem) * 0.5);
}

// 在庫削除選択
.w-stocks-select-delete-stocks {
  max-width: calc(80vw - 260px - 2rem - 6rem - 6rem - 6rem - 7rem - 5rem);
}

// 在庫消費
// 消費履歴
.w-stocks-consumptions-item-name {
  max-width: calc(80vw - 260px - 3rem - 5rem - 5rem - 5rem - 5rem - 5rem - 3rem - 5rem - 6rem);
}
// 在庫一覧
// モーダル内部前提
.w-stocks-consumptions-stocks-item-name {
  max-width: calc(800px - 3rem - 5rem - 5rem - 5rem - 5rem - 5rem - 5rem);
}
// 在庫変動履歴
.w-stocks-stock-histories-fd-item-user {
  max-width: calc(800px - 3rem - 7rem - 5rem - 4rem - 6rem - 7rem);
}

// 棚卸
.w-stocks-inventory-n-months-name {
  max-width: calc(80vw - 260px - 3rem - 7rem - 7rem - 7rem);
}

.w-stocks-inventory-group-facility-name {
  max-width: calc(80vw - 260px - 3rem - 6rem - 7rem - 18rem - 6rem);
}

.w-stocks-inventory-summaries-item-name {
  max-width: calc(80vw - 260px - 3rem - 5rem - 5rem - 5rem - 5rem - 5rem - 4rem - 5rem - 5rem);
}
.w-stocks-admin-inventory-summaries-item-name {
  max-width: calc(80vw - 260px - 3rem - 5rem - 5rem - 5rem - 5rem - 5rem - 3rem - 3rem - 4rem - 4rem - 5rem - 4rem);
}
// 部署別棚卸点検表編集
.w-stocks-admin-inventory-summaries-select-show-item-name {
  max-width: calc(80vw - 260px - 3rem - 5rem - 8rem - 8rem - 8rem - 5rem);
}
// 部署別棚卸点検表
.w-stocks-inventories-show-dept {
  max-width: calc(80vw - 260px - 3rem - 10rem - 10rem - 7rem);
}
// 商品一覧
// モーダル内部前提
.w-stocks-consumptions-items-item-name {
  max-width: calc(800px - 3rem - 5rem - 5rem - 5rem - 5rem);
}

// 返品一覧
.w-stocks-return-item-item-name {
  max-width: calc(80vw - 260px - 3rem - 10rem - 10rem - 5rem - 3rem - 3rem - 5rem - 7rem);
}

// 払出記録
// ストッキング払出記録
.w-pt-shippings-stockings-department {
  max-width: calc(80vw - 260px - 3rem - 6rem - 9rem - 5rem - 5rem - 9rem);
}
// 血糖測定器払出記録
.w-pt-shippings-glucoses-department {
  max-width: calc(80vw - 260px - 3rem - 6rem - 8rem - 9rem - 6rem - 9rem - 5rem);
}
// 商品払出記録
.w-pt-shippings-standards-item {
  max-width: calc(80vw - 260px - 3rem - 5rem - 6rem - 8rem - 6rem - 5rem - 5rem - 5rem - 5rem - 5rem);
}
// シリアル番号一覧
.w-pt-shippings-serial-numbers-serial-no {
  max-width: calc(80vw - 260px - 3rem - 8rem);
}

// お知らせ
.w-admin-announcement-title {
  max-width: calc(80vw - 260px - 3rem - 10rem - 10rem - 7rem - 7rem - 7rem - 4rem - 5rem);
}

// お伺い書一覧
.w-misc-appl-ask-title {
  max-width: calc(80vw - 260px - 3rem - 8rem - 10rem - 11rem - 5rem - 5rem - 5rem - 6rem);
}

// 承認待ちお伺い書一覧
.w-misc-appr-ongoing-title {
  max-width: calc(80vw - 260px - 3rem - 8rem - 10rem - 11rem - 5rem - 5rem - 6rem);
}

// 稟議情報一覧
.w-asman-consults-title {
  max-width: calc(80vw - 260px - 3rem - 4rem - 7rem - 7rem - 6rem - 6rem - 4rem - 4rem);
}

// 契約情報一覧
.w-asman-contracts-title {
  max-width: calc(80vw - 260px - 3rem - 4rem - 8rem - 6rem - 5rem - 5rem - 5rem - 5rem - 4rem - 4rem);
  min-width: 6rem;
}

// 物件明細一覧
.w-asman-materials-title {
  max-width: calc(80vw - 260px - 3rem - 6rem - 6rem - 6rem - 5rem - 6rem - 6rem);
}

// 契約形態一覧
.w-asman-contracts-form-code-title {
  max-width: calc(80vw - 260px - 3rem - 8rem - 8rem);
}

// 指示書一覧
.w-direction-title {
  max-width: calc(80vw - 260px - 3rem - 12rem - 12rem - 6rem - 8rem);
}

// 支払一覧
.w-asman-payment-contract-name {
  max-width: calc(80vw - 260px - 6rem - 6rem - 7rem - 5rem - 5rem - 5rem);
  min-width: 6rem;
}

// 更新者等を表示するための行
div.row.updater {
  color: #757575; // text-secondaryと同じ色
  line-height: 1.25em;
  font-size: 80%;
}
.modal-body {
  div.row.updater {
    margin-top: -1.5rem;
    margin-bottom: 0.5rem;
  }
}

// cardの高さ保証用
.h-70vh {
  min-height: 70vh; // view portの70%
}

// menuのアイコン用style上書き(material dashboardのデフォではでかくなるので)
.sidebar {
  .nav {
    i,svg{
        font-size: 14px;
        float: left;
        margin-right: 2px;
        line-height: 30px;
        width: auto;
        min-width: 18.5px;
        text-align: center;
        color: white;
        margin-top: 8px;
    }
  }
}
// logoアイコン
.sidebar .logo a.logo-mini {
  opacity: 1;
  float: left;
  width: 23px;
  text-align: left;
  margin-left: 0;
  margin-top: 0;
}

// stickyで下にはりつくボタン
// cardの中に入れることを想定している
// card側のpadding-bottomをzeroにしておくときれいに出る
div.sticky-button-area {
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #dee2e6;
  background: rgba(255, 255, 255, 0.75);
}

// 商品マスタの折りたたみ機能用スタイル
// 開いた状態
a.toggle-link,
a.facility-toggle-link,
a.expired-at-toggle-link,
a.association-toggle-link {
  .up-icon { // 閉じるためのupを表示して
    display: inline;
  }
  .down-icon { // downは非表示
    display: none;
  }
}
// 閉じた状態, 上の逆パターン
a.toggle-link.collapsed,
a.facility-toggle-link.collapsed,
a.expired-at-toggle-link.collapsed,
a.association-toggle-link.collapsed {
  .up-icon {
    display: none;
  }
  .down-icon {
    display: inline;
  }
}

// 商品選択画面のお気に入り
.add-favorite-btn {
  &:hover {
    cursor: pointer;
  }
  .fav-icon{
    color: #757575;
    font-size: 16px;
  }

  &.in-favorite {
    .fav-icon{
      color: #ff9800;
    }
  }
}

.del-favorite-btn {
  &:hover {
    cursor: pointer;
  }
  .fav-icon{
    color: #f44336;
    font-size: 16px;
  }
}

.text-blue {
  color: blue !important;
}

.pop-select-list-group {
  .list-group-item {
    font-size: 12px;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    &:hover {
      color: white;
      background-color: theme-color('bs-primary');
      border-color: theme-color('bs-primary');
    }
  }
}

.list-popover-body {
  padding: 0;
}

.btn-field {
  .btn {
    margin-top: 4px;
  }
}

.text-black {
  color: #333333 !important;
}

// 長い文字列を改行する
.text-break {
  word-wrap: break-word!important;
  word-break: break-word!important;
}

table.matrix {
  th,td {
    font-weight: normal;
    border-color: #dddddd;
  }
  td.c2:not(.not-click), td.c3:not(.not-click) {
    &:hover, &:focus {
      background-color: #ecf2fa !important;
      cursor: pointer;
    }
  }

  // c0 商品名のセル

  // 申請数が0の時
  td.c1 {
    background: #c0c0c0 !important;
  }

  // 申請数に対して、在庫払出予約数が少ない時
  td.c2 {
    background: #fac8c8 !important;
  }

  // 申請数に対して、在庫払出予約数が多い時
  td.c3 {
    background: #c8fac8 !important;
  }

  td.error {
    background: #fac8c8 !important;
  }

}

// 発注などで横長になる表
table.matrix-table {
  > thead > tr > th,
  > tbody > tr > td {
    padding: 0.125rem 0.25rem;
    font-size: 0.875rem;
  }
}

.installment-delivery-item {
  padding-right: 0 !important; // after擬似要素が収まりやすくなるようpaddingをなくす
}

.installment-delivery-item:after {
  content:"分納中";
  color: #ffffff;
  background-color: #ff0000;
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.7rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
  margin-left: 3px;
}

.spd-item {
  padding-right: 0 !important; // after擬似要素が収まりやすくなるようpaddingをなくす
}
.spd-item:after {
  content:"SPD品";
  color: #ffffff;
  background-color: #ff0000;
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.7rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
  margin-left: 3px;
}
.maintenance-pack-item {
  padding-right: 0 !important; // after擬似要素が収まりやすくなるようpaddingをなくす
}
.maintenance-pack-item:after {
  content:"メンテナンスパック";
  color: #ffffff;
  background-color: #ff0000;
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.7rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
  margin-left: 3px;
}

.barcode-scan {
  padding-right: 0 !important; // after擬似要素が収まりやすくなるようpaddingをなくす
}
.barcode-scan:after {
  content: "スキャン";
  color: #ffffff;
  background-color: #4caf50; // success
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.5rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
  // margin-left: 3px;
  margin-top: 1px;
}

// 請求書などの合計欄を右寄せにするため、左側のtdの線を消すスタイル
td.no-lb-border {
  border-left: none;
  border-bottom: none;
}
td.no-tlb-border {
  border-top: none;
  border-left: none;
  border-bottom: none;
}

// 商品マスタのサブメニュー用チェックマーク

a.with-checked {
  &:before {
    content: "\2714";
    font-weight: 900;
    font-size: 1.0rem;
    position: absolute;
    color: blue;
    //color: theme-color("navy");
    right: 1rem;
  }
  &:hover:before {
    content: "\2714";
    font-weight: 900;
    font-size: 1.0rem;
    position: absolute;
    color: white;
    //color: theme-color("navy");
    right: 1rem;
  }
}

// 商品一覧画面の、関連商品リンクのスタイル
a.associated-item-link {
  color: #333333;
  text-decoration: underline;
}

// 商品編集画面の関連商品削除ボタン
.remove-association-field-box {
  padding-top: 4px;
  .add-item-field, .remove-item-field {
    font-size: 21px;
    cursor: pointer;
  }
}

// modalの中身を非表示にする
.modal-body.hidden {
  visibility: hidden;
}

// 在庫使用期限
.stock-expired-at {
  &.exp-ealiest {
    padding-right: 0 !important; // after擬似要素が収まりやすくなるようpaddingをなくす
  }
  &.exp-ealiest:after {
    content:"最短期限";
    color: #ffffff;
    background-color: #ff0000;
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    font-size: 0.7rem;
    font-weight: 700; //$badge-font-weight;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: text-top;
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    @include border-radius($badge-pill-border-radius);
    margin-left: 3px;
  }
}

// chromeのinput type dateのカレンダーの左のマージンを減らす
::-webkit-calendar-picker-indicator {
  margin-left: 0px;
}

// サイドバーの通知数を表すマーク
.sidebar-notification,.sidebar-notification-upper,.sidebar-notification-upper-sum {
  // margin-top: 4.5px; // align-items-centerを指定しない場合はこれを指定すれば上下中央になるはず
  border: 1px solid #FFF;
  font-size: 14px;
  background: theme-color(danger);
  // background: darken(theme-color(warning), 5%); // 文字が見える程度に暗くしたオレンジ
  // background: theme-color(bs-primary); // 青
  color: #FFFFFF;
  min-width: 20px;
  padding: 0px 5px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 19px;
  font-weight: bold;
}

// ユーザの権限プリセットに関連するクラス
@media only screen and (max-width: 575px) {
  .permission-dropdown {
    position: absolute;
    left: 50px;
    top: 10px;
    margin: 0px !important;
    padding: 0px !important;
    z-index: 10;
  }
}

@media only screen and (min-width: 576px) {
  .permission-dropdown {
    position: absolute;
    top: 40px;
    margin: 0px !important;
    padding: 0px !important;
    left: 15px;
    z-index: 10;
  }
}

// fullcalendarの設定
.fc-event {
  &.event-holiday {
    // googleカレンダーの祝日の色
    background-color: rgb(213, 0, 0);
    border-color: rgb(213, 0, 0);
  }
  cursor: pointer; // editableをfalseにするとpointerにならないが、強制的に設定
}
a.fc-daygrid-day-number {
  // material-dashboardの色担ってるので変更
  color: #444;
}

// 一括編集用
.multi-select-btn-area {
  text-align: center;
}
#multi-items-confirm-modal,#multi-suppliers-confirm-modal {
  table {
    font-size: 12px;
    th,td{
      font-size: 12px !important;
      padding: 2.5px 5px 2.5px 5px !important;
    }
  }
}

// モーダル上で経路を表示するためのcard-headerとcard-bodyの定義
.modal .card.card-plain.card-signup .card-for-template {
  .card-header {
    margin: 0;
    padding: 0;
    position: relative;
  }
  .card-body {
    padding: 0.9375rem 20px;
    position: relative;
  }
}

// overflowを指定するとsortができないのでoverflowのプロパティを抜いたtable-responsiveのスタイルを作成
.table-responsive-sort {
  display: block;
  width: 100%;
}

// inputのdisabledとreadonlyのデザインをmaterial-dashboardと同じものを定義
.simple-form:disabled,.simple-form[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

@media (min-width: 576px) {
  .analyst {
    height: 445.5px;
  }
}

@media (min-width: 768px) {
  .analyst {
    height: 421.5px;
  }
}

@media (min-width: 992px) {
  .analyst {
    height: 445.5px;
  }
}

@media (min-width: 1200px) {
  .analyst {
    height: 397.5px;
  }
}

@media (max-width: 575px) {
  .lavel-box{
    display: flex;
  }

  .label-w{
    flex-basis: 100px;
  }

  .btn-mg{
    align-self: flex-start;
  }
}

@media (min-width: 576px) {
  .lavel-box{
    padding-left: 10px;
  }

  .label-w{
    max-width: 100%;
    padding-right: 0%;
  }

  .btn-mg{
    margin-left: 20px;
  }
}

@media (min-width: 880px) {
  .lavel-w{
    white-space: nowrap;
  }
}

@media (min-width: 1680px) {
  .lavel-box{
    display: flex;
  }

  .label-w{
    flex-basis: 100px;
  }

  .btn-mg{
    margin-left: 7px;
    right:10px;
    align-self: flex-start;
  }
}

// 縦に長いコンテンツのダウンドード時に最初の1ページしか印刷できないというバグを防止するための設定　
// windows版chromeで印刷したときに起きるバグ対応
@media print and (max-width: 991px) {
  body {
    overflow-x: visible;
  }
}

.modal-fullscreen {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

#cameraModal {
  .modal-content {
    background:rgba(0,0,0,0.75);
  }
}

.float-block {
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: 0px;
  .float-items-area {
    padding: 20px 20px 20px 20px;
    background-color: #f8f6f6;
  }
}

// safariで値がブランクの時、なぜか高さがおかしくなる。その回避策
input[type="date"].bs-form-control {
  min-height: 2rem;
}

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: 50px!important;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  text-overflow: ellipsis;
}

.dashboard-menu-card {
  cursor: pointer;
  .dashboard-menu-text {
    font-size: 1.20rem;
  }
  .dashboard-menu-long-text {
    font-size: 1rem;
  }
}
.dashboard-menu-card:hover {
  background-color: #c8e6fa;
}

.dropdown {
  .dropdown-menu.show {
    .dropdown-item.dropdown-toggle + .dropdown-menu.show {
      right: 101% !important;
      left: auto !important;
    }
  }
}

// 背景画像へのfilterの上書き
.header-filter::before {
  background: rgba(0, 0, 0, 0);
}

// calendar event 登録modal用
#radio-supp:checked {
  & ~ label.label-for-supp {
    font-weight: bold;
    color: blue;
  }
  & ~ div#supp-div {
    display: block;
  }
  & ~ div#normal-div {
    display: none;
  }
}

#radio-normal:checked {
  & ~ label.label-for-normal {
    font-weight: bold;
    color: blue;
  }
  & ~ div#supp-div {
    display: none;
  }
  & ~ div#normal-div {
    display: block;
  }
}

.border-left-dotted {
  border-left: 1px dotted #dee2e6;
}

.keypad-num {
  text-align: center;
  background-color: #f4f4f4;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: 1px;
  cursor: pointer;
}
